import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import Mediaevent from '../../components/media-events/mediaevent'

const MediaPage = () => {
  return (
    <div>
      <Header/>
      <Mediaevent/>
      <Footer/>
    </div>
  )
}

export default MediaPage