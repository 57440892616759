import React from "react";
import Donation from "../../components/donation";
import Footer from "../../components/footer";
import Header from "../../components/header";

const DonationPage = () => {
  return (
    <div>
      <Header />
      <Donation />
      <Footer />
    </div>
  );
};

export default DonationPage;
