import React from 'react'
import './mediaevent.css'
import Event from './../events/index';
const Mediaevent = () => {
  return (
    <div className="mediaEventContainer">
      <div className="mediaEventBanner">
        <h1>MEDIA & EVENTS</h1>
      </div>

      <div className="mediaEventContent">
       <Event/>
      </div>
    </div>
  )
}

export default Mediaevent