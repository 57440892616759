import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Home from "../../components/home";

const HomePage = () => {
  return (
    <>
      <Header />
      <Home />
      <Footer />
    </>
  );
};

export default HomePage;
