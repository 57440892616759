import React from "react";
import "../aboutUs/about.css";

const About = () => {
  return (
    <div className="aboutContainer">
      <div className="aboutBanner">
        <h1>ABOUT US</h1>
      </div>

      <div className="aboutContent">
        <p>
          हम आजाद भारत की आशास्पद वो युवा पीढ़ी है जो मां भारती की गरिमा और कद
          को बढ़ाने का दमखम रखती है । पर जब तक आपके पास कोई मकसद नहीं होता आप
          कार्य को अंजाम नई दे सकते और मकसद होने के बाद भी डिसीजन पावर आपके पास
          न रहा तो सही कार्य को आप कभी अंजाम नई दे सकते । युवाओं के पथदर्शक श्री
          स्वामी विवेकानंद को हम हमारे आदर्श मानते हुए नई पीढ़ी के प्रतिभासली
          युवाओं को साथ रखते हुए युवा सोच को गति देने के लिए संगठित होकर एक नई
          दिशा में कदम रखते हुए युवा स्वप्नों को साकार करने की दिशा में आगे
          बढ़ते हुए राजनीति में हमारा पहला कदम रखते हुए byjep की नीव रखते है।
        </p>
      </div>
    </div>
  );
};

export default About;
