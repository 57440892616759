import React from 'react'
import Footer from '../../components/footer';

import Header from '../../components/header/index';
import JoinParty from '../../components/joinParty';


const JoinPage = () => {
  return (
    <div>
      <Header/>
      <JoinParty/>
      <Footer/>
    </div>
  )
}

export default JoinPage