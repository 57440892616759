import React from "react";
import "./home.css";
import event1 from "../../assests/image/nomination1.jpeg";
import event2 from "../../assests/image/nomination2.jpeg";
import event3 from "../../assests/image/partyImage.png";
import PartyHistory from "../party-history/index";
import Event from "../events";

import Button from "../button/Button";
import PartyPresident from "../party-president";

function Home() {
  const joinStyle = {
    backgroundColor: "#f68634 !important",
    border: "3px solid #f08637",
    borderRadius: "12px",
    opacity: 1,
    height: "40px",
    width: "185px",
    color: "#f0f0ed",
    font: "Poppins",
    fontWeight: "750",
    padding: "10px",
    marginLeft: "80px",  
    
    background: "#F08637 0% 0% no-repeat padding-box",
  };

  const donationStyle = {
    backgroundColor: "#f68634 !important",
    border: "3px solid #f08637",
    borderRadius: "12px",
    opacity: 1,
    height: "40px",
    width: "185px",
    color: "#f0f0ed",
    font: "Poppins",
    fontWeight: "750",
    padding: "10px",
    marginLeft: "40px",
    background: "#F08637 0% 0% no-repeat padding-box",
  };
 

  return (
    <div className="homeBodyContent">
      <img
        src={require("../../assests/image/Slider_01.png")}
        alt="SliderImg"
        className="sliderImage"
      />

      <div className="mission">
        <div className="missionImageSection">
          <img
            src={require("../../assests/image/boys.png")}
            alt="Kids"
            className="missionImg"
          />
        </div>
        <div className="missionTxt">
          <h2 className="missionheadingtxt">Our Mission</h2>
          <span className="missiontextPara">
            हम आजाद भारत की आशास्पद वो युवा पीढ़ी है जो मां भारती की गरिमा और कद
            को बढ़ाने का दमखम रखती है । पर जब तक आपके पास कोई मकसद नहीं होता आप
            कार्य को अंजाम नई दे सकते और मकसद होने के बाद भी डिसीजन पावर आपके
            पास न रहा तो सही कार्य को आप कभी अंजाम नई दे सकते ।
            <br />
            <br />
            युवाओं के पथदर्शक श्री स्वामी विवेकानंद को हम हमारे आदर्श मानते हुए
            नई पीढ़ी के प्रतिभासली युवाओं को साथ रखते हुए युवा सोच को गति देने
            के लिए संगठित होकर एक नई दिशा में कदम रखते हुए युवा स्वप्नों को
            साकार करने की दिशा में आगे बढ़ते हुए राजनीति में हमारा पहला कदम रखते
            हुए byjep की नीव रखते है।
            <br />
            <br />
            एक अधीर सोच ने आशा की नई किरण लाते हुए हमारे क्रांतिकारी कदमों को और
            मजबूत किया और संत महात्माओं और गुरुवरो के आशीर्वाद प्राप्त हुए |
          </span>
        </div>
      </div>
      <div className="vision">
        <div className="visionTxt">
          <h2 className="visionheadingtxt">Our Vision</h2>
          <span className="visiontextPara">
            और उनके प्रेरणादाई पथदर्शन से युवा भारत के बढ़ते कदम को युवाओं के
            विचारो और कार्य से ही चरितार्थ करने की हमारी कोशिश एक राजनीतिक
            पार्टी के रूप में जन्म लेने जा रही थी जिनका मकसद भारत के बढ़ते कदमों
            को और आगे युवाओं के साथ लेजाना है ।<br />
            <br />
            कई कार्य को परदे के पीछे से अंजाम दे रहे थे जो देश हित में थे पर
            उनमे से बहुत से पूरे न हो पाए बस ये एक अधीर सोच ने आशा की नई किरण
            लाते हुए हमारे क्रांतिकारी कदमों को और मजबूत किया और संत महात्माओं
            और गुरुवरो के आशीर्वाद प्राप्त हुए
            <br />
            <br />
            और उनके प्रेरणादाई पथदर्शन से युवा भारत के बढ़ते कदम को युवाओं के
            विचारो और कार्य से ही चरितार्थ करने की हमारी कोशिश एक राजनीतिक
            पार्टी के रूप में जन्म लेने जा रही थी जिनका मकसद भारत के बढ़ते कदमों
            को और आगे युवाओं के साथ लेजाना है
          </span>
        </div>
        <div className="visionImageSection">
          <img
            src={require("../../assests/image/oldman.png")}
            alt="Kids"
            className="visionImg"
          />
        </div>
      </div>
      <div className="memberSathi">
        <div className="left"></div>
        <div className="right">
          <h2>हमारे साथी बनो।</h2>
          <p>
            BYJEP की विचारधारा समाज के सभी वर्गों को उनकी जनसंख्या के अनुसार सभी
            को समान अधिकार, सभी वंचित, शोषित, मजदूर, किसान, गरीब एवं महिलाओं के
            सशक्तिकरण एवं उनके अधिकारों को दिलाना एकमात्र लक्ष्य है। हमारे सफर
            में हमारा साथी बनिए।
          </p>
          <div className="btns">
            <Button title="Become a Member" style={joinStyle} />
            <Button title="Make Donation" style={donationStyle} />
          </div>
        </div>
      </div>

      
      <PartyPresident/>
      <PartyHistory />
      <div className="eventSection">
        <h2>Events</h2>
        <span className="colorBorderEvent"></span>
        <div className="eventCardRender">
          <Event event={event1} />
          <Event event={event2} />
          <Event event={event3} />
        </div>
      </div>
      
    </div>
  );
}

export default Home;
