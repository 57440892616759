import React from 'react'
import Footer from '../../components/footer';
import Header from '../../components/header'
import Manifesto from '../../components/manifesto';



const ManifestoPage = () => {
  return (
    <div>
      <Header/>
      <Manifesto/>
      <Footer/>
    </div>
  )
}

export default ManifestoPage