import { Route, Routes } from "react-router-dom";
import "./App.css";
import AboutPage from "./pages/aboutPage";
import HomePage from "./pages/homePage";
import ManifestoPage from "./pages/manifesto";
import JoinPage from './pages/joinPage';
import DonationPage from './pages/donationPage/index';
import MediaPage from "./pages/mediaPage";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        </Routes>
      <Routes>
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <Routes>
        <Route path="/media" element={<MediaPage/>} />
      </Routes>
      <Routes>
        <Route path="/manifesto" element={<ManifestoPage />} />
      </Routes>
      <Routes>
        <Route path="/join" element={<JoinPage />} />
      </Routes>
      <Routes>
        <Route path="/donation" element={<DonationPage />} />
      </Routes>      
    </div>
  );
}

export default App;
