import React from "react";
import "./events.css";

const Event = (props) => {
  return (
    <div className="eventCard">
      <div className="eventImg">
        <img src={props.event} />
      </div>
      <div className="eventDescription">
        <div className="pEvent">
          <h4>Party Event</h4>
        </div>
      </div>
    </div>
  );
};

export default Event;
