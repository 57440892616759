import React from 'react'
import About from '../../components/aboutUs';
import Footer from '../../components/footer';
import Header from '../../components/header';

const AboutPage = () => {
  return (
    <div>
        <Header/>
        <About/>
        <Footer/>
    </div>
  )
}

export default AboutPage;