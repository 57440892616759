import React from 'react'
import './donation.css'
const Donation = () => {
  return (
    <div className="mediaMakeDonationContainer">
      <div className="mediaMakeDonationBanner">
        <h1>Make Donation</h1>
      </div>

      <div className="mediaMakeDonationContent">
       
      </div>
    </div>
  )
}

export default Donation