import React from 'react'
import './manifesto.css'


const Manifesto = () => {
  return (
    <div className="mediaManifestoContainer">
      <div className="mediaManifestoBanner">
        <h1>PARTY MANIFESTO</h1>
      </div>

      <div className="mediaManifestoContent">
       
      </div>
    </div>
  )
}

export default Manifesto