import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Button from "../button/Button";



function Header() {

  const style = {   

    backgroundColor: "#f68634 !important",
    border: "3px solid #f08637",
    borderRadius: "12px",
    opacity: 1,
    height: "40px",
    width: "105px",
    color: "#f0f0ed",
    fontWeight: "700",
    padding: "10px",
    background: "#F08637 0% 0% no-repeat padding-box",
  };

  return (
    <div className="mainContainer">
      <div className="subContainer">
        <img src= {require('../../assests/image/Group 4.svg').default } alt ='Logo' className="Logo"/>
        <div className="leftPanel">Bharatiya Yuva Jan Ekta Party</div>
        <div className="rightPanel">
          <Link exact to="/" style={{textDecoration: 'none' }}>
            <p>Home</p>
          </Link>
          <Link to="/about" style={{textDecoration: 'none' }}>
            <p>About Us</p>
          </Link>
          <Link to="/media" style={{textDecoration: 'none' }}>
            <p>Media & Events</p>
          </Link>
          <Link to="/manifesto" style={{textDecoration: 'none' }}>
            <p>Party Manifesto</p>
          </Link>
          <Link to="/join" style={{textDecoration: 'none' }}>
           <Button title = "Join BYJEP" style = {style}/>
          </Link>
          <Link to ='/donation' style={{textDecoration: 'none' }}>
             <Button title = "Donation" style = {style}/>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
