import React from "react";
import "./footer.css";

function Footer() {
  return (
    <div className="footerMainContainer">
      <div className="contactUs">
        <h4>CONTACT US</h4>
        <p className="info">+91 6352497117</p>
        <p className="info ">Byjep.india@gmail.com</p>
        <p className="info ">Jaipur Rajasthan</p>
      </div>
      <div className="information">
        <h4 >INFORMATION</h4>
        <p className="info">Home</p>
        <p className="info">About</p>
        <p className="info">Join Us</p>
        <p className="info">Make Donation</p>
      </div>
      <div className="newsLetter">
        <h4>SUBSCRIBE TO OUR NEWSLETTER</h4>
        <input className="input-field" placeholder=" Email Address"/>
        <button className="subscribe-btn">SUBSCRIBE</button>
      </div>
    </div>
  );
}

export default Footer;
