import React from 'react'


const Button = (props) => {
 const {title, style} = props;  

  return (
    <div >
        <button style={style}>{title}</button>
    </div>
  )
}

export default Button