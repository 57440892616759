import React from 'react'
import './partyPresident.css'
const PartyPresident = () => {
  return (
    <div className="partyPresident">
        <h2>Party President</h2>
        
        <div className="pPresidentNametag">
          <div className="ppImage">
            <img src={require("../../assests/image/ankit.jpeg")} alt="" />
            <h3>Shah Ankit</h3>
            <p>राष्ट्रीय अध्यक्ष</p>
          </div>
          <div className="ppDetails">
            <p>
              असंतोष आपको एक नई दिशा में ले जाने की प्रेणना देता है । यदि सब
              अच्छा ही होता तो हम एक आदर्श व्यवस्था होते पर ये हो नई सकता
              क्योंकि मानव मात्र त्रुटिपूर्ण होता है और कुछ कामियो जो हमे कई बार
              अंदर से जाजोलकर रख देती है । हमारे सामने हम कई ऐसी चीजों को होते
              हुए देखते है की हमे लगता है की कुछ बदलाव जरूरी है ।<br />
              <br />
              युवाओं की राष्ट्र को समर्पित होकर कार्य करने के जस्बे को हम कायम
              रखने की कोशिश कर रहे है । जातपात के भेदभाव से रहित होकर एकजुट होकर
              कार्य करने की भावना को अंजाम देना हमारा मकसद रहा है । <br />
              <br />
              कई ऐसे मौके आए जहा पर हम बहुत कुछ कर सकते है ऐसा लगता है पर हम कुछ
              नई कर सकते क्युकी हमारे हाथ में नई है बस यही सोच एक क्रांति लाने
              के लिए काफी थी युवाओं का आक्रोश और जोश हमे भारत मां के प्रति
              कर्तव्यनिष्ठ होकर एक नई सुबह की शुरुआत लाने की प्रेरणा दे रहा था ।{" "}
              <br />
              <br />
              कई कार्य को परदे के पीछे से अंजाम दे रहे थे जो देश हित में थे पर
              उनमे से बहुत से पूरे न हो पाए बस ये एक अधीर सोच ने आशा की नई किरण
              लाते हुए हमारे क्रांतिकारी कदमों को और मजबूत किया और संत महात्माओं
              और गुरुवरो के आशीर्वाद प्राप्त हुए और उनके प्रेरणादाई पथदर्शन से
              युवा भारत के बढ़ते कदम को युवाओं के विचारो और कार्य से ही चरितार्थ
              करने की हमारी कोशिश एक राजनीतिक पार्टी के रूप में जन्म लेने जा रही
              थी जिनका मकसद भारत के बढ़ते कदमों को और आगे युवाओं के साथ लेजाना
              है ।
            </p>
          </div>
        </div>
      </div>
  )
}

export default PartyPresident