import React from "react";
import "./joinParty.css";

const JoinParty = () => {
  return (
    <div className="mediaJoinPartyContainer">
      <div className="mediaJoinPartyBanner">
        <h1>JOIN BYJEP</h1>
      </div>

      <div className="mediaJoinPartyContent">
        <div className="joinPartyInputHeader">
          <h2>Join Bhartiya Jan Ekta Party</h2>
        </div>
        <form>
          <div>
            <input type="text" name="name" placeholder="First Name" />
            <input type="text" name="name" placeholder="Last Name" />
          </div>
          <div>
            <input type="text" name="name" placeholder="Father Name" />
            <input type="number" name="phone" placeholder="Phone Number" />
          </div>

          <div>
            <select>
              <option value="select">Select</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinParty;
